<template>
  <div class="programs">
    <div class="programs__info-box info-box container d-flex">
      <div class="info-box__left">
        <div class="info-box__title">{{ $t('header.myPrograms') }}</div>
        <div class="info-box__description">{{ $t('supportText.selectAProgramToEdit') }}</div>
      </div>
      <div class="info-box__right">
        <AddButtonWithIcon :title="$t('buttonLabels.createProgram')" @click="createProgram" />
      </div>
    </div>
    <ProgramsFilter :selectFilter="selectFilter" :filter="filter" />
    <transition name="component-fade" mode="out-in">
      <div key="loading" v-if="loading" class="programs__loader">
        <div class="container f-center">
          <Loader />
        </div>
      </div>
      <div key="error" class="container text-primary" v-else-if="loadingError">
        <span @click="$router.go()" class="text-underline cursor-pointer text-primary"
          >{{ $t('errorMessages.somethingWentWrong') }}
        </span>
      </div>
      <div key="content" v-else-if="programs.length">
        <ProgramsContent
          :pagination="pagination"
          :programs="programs"
          :filter="filter"
          :selectFilter="selectFilter"
          :createProgram="createProgram"
        />
      </div>
      <div key="empty-state" v-else>
        <div class="container pt-0 mb-5">
          <template v-if="(filter.author && !filter.manager) || (filter.author && filter.manager)
           || (!filter.author && !filter.manager)">
            <div class="empty-state__title">{{ $t('header.createTheFirstProgram') }}</div>
            <div class="empty-state__images images">
              <div class="images__wrapper">
                <div class="image">
                  <img src="@/assets/images/svg/board.svg" alt="Доска" />
                  <div class="image__text">{{ $t('supportText.fillWithCourses') }}</div>
                </div>
                <div class="image">
                  <img src="@/assets/images/svg/bookshell.svg" alt="Книжная полка" />
                  <div class="image__text">{{ $t('supportText.addHelpfulMaterials') }}</div>
                </div>
                <div class="image">
                  <img src="@/assets/images/svg/user.svg" alt="Пользователь" />
                  <div class="image__text">{{ $t('supportText.inviteUsersForTraining') }}</div>
                </div>
              </div>
            </div>
            <div class="empty-state__action">
              <AddButtonWithIcon :title="$t('buttonLabels.createProgram')" @click="createProgram" />
            </div>
          </template>
          <template v-if="filter.manager && !filter.author">
            <div class="empty-state__title mb-0">{{ $t('header.youHaveNotBeenAddedAsManager')
              }}</div>
            <div class="empty-state__images images">
              <div class="images__wrapper wide-svg-bg pt-45">
                <div class="image">
                  <img src="@/assets/images/svg/bookshelf.svg" alt="Книжная полка" />
                  <div class="image__text max-w-16 mt-25">
                    {{ $t('supportText.toGetAccessContactAuthor') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';

import AddButtonWithIcon from '@/components/Buttons/AddButtonWithIcon.vue';
import Loader from '@/components/Loader/Loader.vue';
import deviceMixin from '@/mixins/device.mixin';
import ProgramsFilter from '@/components/Teacher/Programs/ProgramsFilter.vue';
import ProgramsContent from '@/components/Teacher/Programs/ProgramsContent.vue';

export default {
  name: 'Programs',
  components: {
    ProgramsFilter,
    ProgramsContent,
    Loader,
    AddButtonWithIcon,
  },
  mixins: [deviceMixin],
  data: () => ({
    programs: [],
    loading: true,
    pagination: {
      perPage: 12,
      totalCount: 1,
    },
    filter: {
      author: true,
      manager: true,
    },
    loadingError: false,
  }),
  async created() {
    this.resetRoutePagination();

    const isAllFiltersUnselected = !this.filter.author && !this.filter.manager;
    await Promise.all([
      ActivityService.getPrograms({
        page: 1,
        per_page: this.pagination.perPage,
        authored: this.filter.author,
        managed: this.filter.manager,
      }),
      ActivityService.getTotalCount({
        type: 'program',
        authored: isAllFiltersUnselected ? true : this.filter.author,
        managed: isAllFiltersUnselected ? true : this.filter.manager,
      }),
    ]).then(
      ([{ data: programs }, { data: totalCount }]) => {
        if (programs?.length) {
          this.programs = programs;
        } else {
          this.programs = [];
          window.history.replaceState(null, null, '');
        }

        this.pagination.totalCount = totalCount.total;
      },
      (error) => {
        console.log(error);
        this.loadingError = true;
      },
    );
    this.loading = false;
  },

  methods: {
    resetRoutePagination() {
      this.$route.query.page = '1';
      window.history.replaceState(null, null, '?page=1');
    },
    createProgram() {
      ActivityService.createProgram()
        .then(({ data: _id }) => {
          this.$router.push({
            name: 'Program',
            params: { programId: _id },
          });
        })
        .catch(console.log);
    },
    remove(id) {
      ActivityService.deleteActivity(id)
        .then(() => {
          this.programs = this.programs.filter((course) => course._id !== id);
        })
        .catch(console.log);
    },
    fetchProgramsByPage(page) {
      const isAllFiltersUnselected = !this.filter.author && !this.filter.manager;
      ActivityService.getPrograms({
        page,
        per_page: this.pagination.perPage,
        authored: isAllFiltersUnselected ? true : this.filter.author,
        managed: isAllFiltersUnselected ? true : this.filter.manager,
      })
        .then(({ data }) => (this.programs = data ?? []))
        .catch(console.log);
    },
    selectFilter(key) {
      this.filter[key] = !this.filter[key];
    },
  },
  watch: {
    '$route.query': {
      handler(query) {
        const newPageQuery = query?.page ?? 1;
        return this.fetchProgramsByPage(newPageQuery);
      },
      deep: true,
    },
    filter: {
      handler(newFilter) {
        this.resetRoutePagination();
        this.pagination.page = 1;
        this.loading = true;

        const isAllFiltersUnselected = !newFilter.author && !newFilter.manager;

        Promise.all([
          ActivityService.getPrograms({
            page: 1,
            per_page: this.pagination.perPage,
            authored: isAllFiltersUnselected ? true : newFilter.author,
            managed: isAllFiltersUnselected ? true : newFilter.manager,
          }),
          ActivityService.getTotalCount({
            type: 'program',
            authored: isAllFiltersUnselected ? true : newFilter.author,
            managed: isAllFiltersUnselected ? true : newFilter.manager,
          }),
        ])
          .then(
            ([{ data: programs }, { data: totalCount }]) => {
              if (programs?.length) {
                this.programs = programs;
              } else {
                this.programs = [];
                window.history.replaceState(null, null, '');
              }

              this.pagination.totalCount = totalCount.total;
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => (this.loading = false));
      },
      deep: true,
    },
  },
};
</script>
